var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"287b5a01dfd3c673fce9e5719ebeaf34ff8a101b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/**
 * @desc Sentry client
 * @license Copyright Trashare
 */
import { SentryConfig } from './sentry.config';

SentryConfig.init();
